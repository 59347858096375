@import 'styles/sass/mixins/rem';

.productListing {
  &__image {
    display: block;
    overflow: hidden;
    margin-right: rem(20);
    img {
      max-height: rem(146);
    }
  }

  &__title {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span {
      font-size: rem(18);
      font-weight: bold;
      line-height: rem(24);
      max-height: rem(50);
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__text {
    ul {
      margin: 0;
      padding: 0;
    }

    li {
      list-style: none !important;
      background-image: url('/public/icons/heart-orange.png');
      background-repeat: no-repeat;
      background-position: rem(0) rem(5);
      padding-left: rem(22);
      margin-bottom: rem(5);
    }
  }
}
